import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import $ from "jquery";
import { navigate } from "@reach/router"
/**
 * Component
 */

import { Search } from '@components/icon/icon';

/**
 * Assets
 */

import './styles/_index.scss';

const HomeSearch = () => {

    useEffect(() => {
        let url = "/";

        $(".sales_btn").click(function() {
            var searcValue = $(".search_field_text").val();
            if(searcValue !== "") {
                url = "in-"+searcValue+"/"
            } else {
                url = "in-london/"
            }
            navigate("/property/for-sale/"+url);
            $("html, body").scrollTop(0);
        })

        $(".rent_btn").click(function() {
            var searcValue = $(".search_field_text").val();
            if(searcValue !== "") {
                url = "in-"+searcValue+"/"
            } else {
                url = "in-london/"
            }
            navigate("/property/to-rent/"+url);
            $("html, body").scrollTop(0);
        })
    },[]);

    return (
        <Formik
            initialValues={{ search: '' }}
            validate={values => {
                const errors = {};
                if (!values.search) {
                errors.search = 'Required';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit} className="home-search">
                    <Form.Group>
                        <div className="icon"><Search /></div>
                        <Form.Control type="text" className="search_field_text" placeholder="Street, area or postcode" name="search" onChange={handleChange} onBlur={handleBlur} value={values.search} />
                    </Form.Group>
                    <div className="btns">
                        <button type="submit" className="sales_btn" disabled={isSubmitting}>
                            Buy
                        </button>
                        <button type="submit" className="rent_btn" disabled={isSubmitting}>
                            Rent
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default HomeSearch
