import React, {createRef,useRef} from 'react';
import ReactPlayer from 'react-player';

/**
 * Components
 */
import PlayIcon from '@components/play-icon/play-icon';
class VideoTemplate extends React.Component {
    render() {
        const url = typeof window !== 'undefined' ? window.location.href : '';
        const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': url,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });    
    }
    //console.log('this.props.src',this.props.src)
    var videoURL = ""
    var videoClass = ""
    if(this.props.src) {
        videoURL = this.props.src
    } else {
        videoURL = "https://www.youtube.com/embed/DlV134E3bdQ";
        videoClass = "img-only-container"
    }
    return (
        <>
        <div className={`video-box ${videoClass}`}>
            <ReactPlayer 
                url={videoURL} 
                muted
                controls
                playsinline 
                playing 
                light={this.props.image}
                playIcon={<PlayIcon />}
                height="100%"
                width="100%"
                ref={p => this.p = p}
                onEnded={() => this.p.showPreview()}             
                onPlay={trackerVideo}
            />
        </div>
        </>
    )
}
}

export default VideoTemplate
